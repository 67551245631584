<template>
    <span>
        <v-calendar 
            color="primary" 
            type="week" 
            ref="calendar"
            :start="formatDate(dayStart, 'yyyy-MM-dd')" 
            :short-months="true" 
            :weekdays="weekdaysOrder" 
            :events="events" 
            @click:event="showEvent" />

        <v-menu v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x>
            <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                    <v-toolbar-title v-html="selectedEvent.name" />
                    <v-spacer />
                    <v-btn @click="selectedOpen = false" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <span v-html="selectedEvent.name" /> <br>
                    <span v-html="stringToISODate(selectedEvent.start)" /> <br>
                    <span v-html="stringToISODate(selectedEvent.end)" /> <br>
                    Duration: <span v-html="duration(selectedEvent.start, selectedEvent.end)" />
                </v-card-text>
            </v-card>
        </v-menu>
    </span>
</template>

<script>
import { 
    format as formatDate, 
    formatISO, 
    intervalToDuration,
    formatDuration
} from 'date-fns';

export default {
    name: "WeekCalendar",
    props: ['dayStart', 'events'],
    data: function() {
        return {
            selectedOpen: false,
            selectedElement: null,
            selectedEvent: {},
        };
    },
    computed: {
        weekdaysOrder: {
            get() {
                let newWeekdaysOrder = [];
                let startWeekday = this.dayStart.getDay();
                for (let i = 0; i < 7; i++)  {
                    newWeekdaysOrder.push((startWeekday + i) % 7);
                }
                return newWeekdaysOrder;
            }
        },
    },
    methods: {
        formatDate (date, format) {
            return formatDate(date, format);
        },
        stringToISODate (date) {
            if (date === null || date === undefined)
                return "";
            
            return formatISO(new Date(date));
        },
        duration(startStr, endStr) {
            if (startStr === null || startStr === undefined || endStr === null || endStr === undefined)
                return "";

            return formatDuration(intervalToDuration({start: new Date(startStr), end: new Date(endStr)}))
        },
        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event;
                this.selectedElement = nativeEvent.target;
                requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
    },
}
</script>
