<template>
  <v-app>
    <v-main style="margin-top: 15px">
      <v-container>
        <v-row>
          <v-col md="3">
            <WeekPicker v-model="rangeStart" />
          </v-col>
          <v-spacer md="3" />
          <v-col md="1">
            <DataEditor v-model="events" />
          </v-col>
          <v-spacer md="2" />
          <v-col md="3">
            <WeekNavigation v-model="rangeStart" />
          </v-col>
        </v-row>
        <WeekCalendar 
              :day-start="rangeStart" 
              :events="events" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import WeekCalendar from './components/WeekCalendar.vue';
import WeekNavigation from './components/WeekNavigation.vue';
import WeekPicker from './components/WeekPicker.vue';
import DataEditor from './components/DataEditor.vue';

export default {
  name: 'App',

  components: {
    WeekCalendar,
    WeekNavigation,
    WeekPicker,
    DataEditor,
  },

  data: function(){
    return {
        rangeStart: new Date(),
        events: [],
    };
  },
};
</script>
