<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="800">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">
                    Edit data
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Edit data
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <RequestReader v-model="requestEvents"/>
                        </v-row>
                        <DateRanges v-model="customDateRanges"/>
                    </v-container>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="updateData" text>
                        Done
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import DateRanges from './DateRanges.vue';
import RequestReader from './RequestReader.vue';

export default {
    name: "DateEditor",
    params: {
        value: {
            required: true,
        }
    },
    components: {
        DateRanges,
        RequestReader,
    },
    data () {
        return {
            dialog: false,
            requestEvents: [],
            customDateRanges: [],
        }
    },
    methods: {
        updateData() {
            this.dialog = false;
            let events = [].concat(this.requestEvents).concat(this.customDateRanges);
            this.$emit('input', events);
        }
    }
}
</script>
