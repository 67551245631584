<template>
    <v-container>
        <v-row>
            <v-text-field 
                v-model="rawRequest"
                label="Request data"
                :error="requestInvalid"/>
        </v-row>
    </v-container>
</template>

<script>
import { eachDayOfInterval, format as formatDate } from 'date-fns';

export default {
    name: 'RequestReader',
    props: {
        value: {required: true}, 
        periodColor: {
            type: String,
            default: 'blue'
        },
        workingDayColor: {
            type: String,
            default: 'orange'
        },
        userAvailabilityColors: {
            default: () => ['indigo', 'deep-purple', 'cyan', 'darkCyan', 'grey darken-1']
        },
    },
    data: function(){
        return {
            rawRequest: '',
            requestInvalid: false,
        }
    },
    watch: {
        rawRequest: function(rawReq) {
            this.requestInvalid = false;
            let events = [];
            try{
                const request = JSON.parse(rawReq, function(field, val) {
                    switch (field) {
                        case 'period_start':
                        case 'start':
                        case 'end':
                        case 'period_end':
                            return new Date(val);
                        default:
                            return val;
                    }
                });
                
                events.push({
                    name: 'Search period',
                    color: this.periodColor, 
                    timed: true, 
                    category: 'period',
                    start: request.period_start, 
                    end: request.period_end, 
                });

                eachDayOfInterval({start: request.period_start, end: request.period_end}).forEach(day => {
                    events.push({
                        name: 'Working day',
                        color: this.workingDayColor,
                        timed: true,
                        category: 'working-day',
                        start: new Date(formatDate(day, 'yyyy-MM-dd') + 'T' + request.day_start + formatDate(day, 'XXX')),
                        end: new Date(formatDate(day, 'yyyy-MM-dd') + 'T' + request.day_end + formatDate(day, 'XXX')),
                    });
                });

                request.users.forEach(user => {
                    if (user.availability !== undefined && user.availability !== null) {
                        user.availability.forEach((availableTimeSlot, index) => {
                            events.push({
                                name: user.email + " #" + index + " available slot",
                                color: this.userAvailabilityColors[index % this.userAvailabilityColors.length],
                                timed: true,
                                category: user.email + "-availability",
                                start: availableTimeSlot.start,
                                end: availableTimeSlot.end,
                            });
                        });
                    }
                });
            } catch(e) {
                this.requestInvalid = true;
                console.log(e);
                return;
            }

            this.$emit('input', events);
        }
    }
}
</script>