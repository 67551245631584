<template>
    <v-container>
        <v-row>
            <v-btn @click="addDays(-7)" style="margin-end: 15px">
                <v-icon>mdi-chevron-double-left</v-icon>
            </v-btn>
            <v-btn @click="addDays(-1)" style="margin-end: 15px">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn @click="setToday" style="margin-end: 15px">
                Today
            </v-btn>
            <v-btn @click="addDays(+1)" style="margin-end: 15px">
                <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
            <v-btn @click="addDays(+7)">
                <v-icon>mdi-chevron-double-right</v-icon>
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
import { add as addToDate } from 'date-fns';

export default {
    name: "WeekNavigation",
    props: ['value'],
    methods: {
        addDays: function(days) {
            this.$emit('input', addToDate(this.value, {days: days}));
        },
        setToday() {
            this.$emit('input', new Date());
        }
    },
}
</script>
