<template>
    <v-menu 
        v-model="datepickerOpened"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto">

        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="formattedRange"
                label="Date range to show"
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                readonly />
        </template>
        
        <v-date-picker
            v-model="computeWeekRange"
            first-day-of-week="1"
            @input="datepickerOpened = false"
            range />
    </v-menu>
</template>

<script>
import { add as addToDate, format as formatDate } from 'date-fns';

export default {
    name: "WeekPicker",
    props: ['value'],
    data: function(){
        return {
            datepickerOpened: false,
        }
    },
    computed: {
        formattedRange: {
            get() {
                return formatDate(this.rangeStart, 'yyyy-MM-dd') 
                + " - " 
                + formatDate(addToDate(this.rangeStart, {days: 6}), 'yyyy-MM-dd');
            }
        },
        computeWeekRange: {
            get() {
                return [formatDate(this.rangeStart, 'yyyy-MM-dd'), 
                formatDate(addToDate(this.rangeStart, {days: 6}), 'yyyy-MM-dd')];
            },
            set([startOfRangeStr]) {
                this.rangeStart = new Date(startOfRangeStr);
            },
        },
        rangeStart: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
}
</script>
