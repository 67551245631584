<template>
    <v-container full-width>
        <v-row style="margin-bottom: -15px">
            <v-spacer md="4" />
            <v-col md="8">
                <v-btn @click="clear">
                    Clear
                </v-btn>
                <v-btn @click="addRange" style="margin-start: 10px">
                    Add range
                </v-btn>
            </v-col>
        </v-row>
        <template v-for="(rng, index) in ranges">
            <v-row :key="index" style="margin-top: 30px">
                <v-col md="12">
                    <v-card>
                        <v-card-text>
                            <v-text-field label="Range data" v-model="rng.data" :error="rng.dataInvalid" />
                            <v-text-field label="Range color" v-model="rng.color" :error="rng.colorInvalid" />
                            <v-text-field label="Range title" v-model="rng.title" :error="rng.titleInvalid" />
                            <v-btn @click="deleteRange(index)">Delete range</v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        },
    },
    data: function(){
        return {
            ranges: [],
        }
    },
    methods: {
        addRange() {
            this.ranges.push({data: "", color: "", title: ""});
        },
        clear() {
            this.ranges = [];
        },
        pickRandomColor() {
            return '#' + Math.floor(Math.random()*16777215).toString(16);
        },
        deleteRange(idx) {
            this.ranges.splice(idx, 1);
        }
    },
    watch: {
        ranges: {
            deep: true,
            handler: function(ranges) {
                console.log("Triggered update ranges");
                let events = [];
                ranges.forEach(range => {
                    range.titleInvalid = false;
                    range.dataInvalid = false;

                    if (range.title == "") {
                        range.titleInvalid = true;
                    }
                    if (range.data == "") {
                        range.dataInvalid = true;
                    }
                    if (range.color == "") {
                        range.color = this.pickRandomColor();
                    }

                    if (range.dataInvalid || range.titleInvalid) {
                        return;
                    }

                    try {
                        const subranges = JSON.parse(range.data, function(field, val) {
                            switch (field) {
                                case 'start':
                                case 'end':
                                    return new Date(val);
                                default:
                                    return val;
                            }
                        });

                        subranges.forEach((subrange, subrangeIdx) => {
                            events.push({
                                name: range.title + " #" + subrangeIdx,
                                color: range.color,
                                timed: true,
                                category: range.title + '-range',
                                start: subrange.start,
                                end: subrange.end
                            });
                        });
                    } catch (e) {
                        console.log(e);
                        range.dataInvalid = true;
                        return;
                    }
                });

                this.$emit('input', events);
            }
        }
    }
}
</script>
